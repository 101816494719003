import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Grid } from '@material-ui/core';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';
import PageSpeedIcon from '../../assets/svg/szybkie-strony.svg';
import FunctionalityIcon from '../../assets/svg/funkcjonalnosc-strony-internetowej.svg';
import ComplexIcon from '../../assets/svg/kompleksowa-usluga.svg';

import OkreslenieWymagan from '../../assets/svg/okreslenie-wymagan.svg';
import ProjektowanieLayoutu from '../../assets/svg/projektowanie-layoutu.svg';
import PrototypowanieImplementacja from '../../assets/svg/prototypowanie-implementacja.svg';
import Publikacja from '../../assets/svg/publikacja-strony-internetowej.svg';
import Wsparcie from '../../assets/svg/wsparcie-i-doradztwo.svg';
import SubLayout from '../layouts/subLayout';
import WordPressIcon from '../../assets/svg/wordpress-plain.svg';
import ReactIcon from '../../assets/svg/react-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const TestPage = () => {
  const data = useStaticQuery(graphql`
        query WebImages {
            responsywne: file(
                relativePath: {
                    eq: "responsywne-strony-internetowe-iqcode.jpg"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            nowoczesne: file(
                relativePath: { eq: "nowoczesny-design-strony-internetowe.jpg" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowa: file(relativePath: { eq: "kompleksowa-usluga.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          <span>Web</span>
          {' '}
          Development
        </>
              )}
      subtitle=""
      img="/img/nextcloud-bg.jpg"
    >
      <SEO title="Web Development - iqcode Software House" description="iqcode is a software house based in Warsaw, Poland. We specialize in building responsive and fast websites in React.js and Gatsby.js" />
      <FeatureSectionLight
        mb0
        invert
        title={(
          <>
            <span>Mobile-First</span>
            {' '}
            Approach
          </>
                  )}
        img={(
          <Img
            alt="responsive websites"
            fluid={data.responsywne.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              As of mid 2021, more than half of internet traffic is generated
              by mobile devices. For that reason, it is particularly important to
              ensure that the developed website is responsive and follows
              best practices of mobile-first web development.
            </p>
            <p>
              Web development projects that we build deliver top notch
              responsiveness, as their various UI elements change
              their sizes and positioning with different device
              screen dimensions. Furthermore, interactive website components
              not only work well with the mouse cursor, but also also optimized
              for touch input of smartphones and tablets. Consequently, our websites offer
              consistent user experience on all supported devices.
            </p>
          </>
                  )}
      />
      <FeatureSectionLight
        title={(
          <>
            <span>Attractive</span>
            {' '}
            Design
          </>
                  )}
        img={(
          <Img
            fluid={data.nowoczesne.childImageSharp.fluid}
            alt="Modern websites"
          />
                )}
        desc={(
          <>
            <p>
              For any product, service or business, its website plays
              important role in the overall brand image. For that reason
              it is particularly important to design it in a manner
              that offers distinctive user experience since the
              very first second of the user's visit.
              In an effort to meet these requirements, we keep
              a clean, modern and professional look and feel of our web projects
              often taking inspiration from design systems such as material design.
            </p>
          </>
                  )}
        link="Get in touch"
        to="/contact/"
      />

      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Want to estimate a
            {' '}
            <span>Web Development Project</span>
            ?
          </h3>
          <Link className="btn" to="/contact/">
            Contact us!
          </Link>
        </div>
      </div>

      <FeatureCardsSection
        title={(
          <>
            Why
            {' '}
            <span>iqcode</span>
            ?
          </>
                  )}
        subtitle="What makes our websites stand out?"
        cards={[
          {
            title: (
              <>
                Quick
                <br />
                Load Times
              </>
            ),
            icon: <PageSpeedIcon />,
            desc:
                            'Modern technologies such as Gatsby.js allow us to deliver great website performance.',
            link: 'Our tech stack',
            to: '/tech-stack/',
          },
          {
            title: (
              <>
                All-rounded
                <br />
                Service
              </>
            ),
            icon: <ComplexIcon />,
            desc:
                            'Thanks to a wide variety of technologies that we use, we can keep up with your project\'s growth.',
            link: 'All our services',
            to: '/',
          },
          {
            title: (
              <>
                Rich
                <br />
                Functionality
              </>
            ),
            icon: <FunctionalityIcon />,
            desc:
                            'Our elaborate backend development skills allow us to implement innovative functionalities without being limited to out-of-the-box solutions.',
            link: 'Backend development',
            to: '/backend-development/',
          },
        ]}
      />

      <div className="technologies bg-dark outline-fix z-depth-3">
        <Container>
          <h3>
            <span>Technologies</span>
            {' '}
            that we use
          </h3>

          <Grid container>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <ReactIcon className="tech-icon" />
              <p>React.js</p>
            </Grid>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <GatsbyIcon className="tech-icon" />
              <p>Gatsby.js</p>
            </Grid>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <WordPressIcon className="tech-icon" />
              <p>WordPress</p>
            </Grid>
          </Grid>
          <Link className="btn btn-alt" to="/tech-stack/">
            Learn more
          </Link>
        </Container>
      </div>

      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Complex </span>
            IT Projects
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="complex web development"
              fluid={data.kompleksowa.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Web development is only the tip of the iceberg of
              what we do at iqcode. Thanks to our wide variety of
              software development technologies, we can deliver
              IT projects cross-cutting numerous domains areas of
              expertise.
            </p>
            <p>
              We often develop custom integrations of our websites with internal CRM and ERP
              systems, e-commerce platforms and analytical apps tailored to the client's business
              domain.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/contact/"
      />
      <AllServices />
    </SubLayout>
  );
};

export default TestPage;
